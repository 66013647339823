import axios from 'axios'

axios.defaults.baseURL = 'https://lift-bf.ru/api/v1/'

const HTTP = axios.create({
    baseURL: 'https://lift-bf.ru/api/v1/',
    headers: {
        'Content-type': 'application/json'
    }
})

export { HTTP }
