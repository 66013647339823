import Vue from 'vue'
import Vuex from 'vuex'

import modal from './modules/modal'
import christmas from './modules/christmas'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
	modules: {
		modal,
		christmas
	},
	strict: debug
})
