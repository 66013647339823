const state = {
    activeName: String,
    isActive: false,
    payload: null,
    activeFail: null,
    activeSuccess: null,
}
const mutations = {
    open(state, { name, payload = null }) {
        state.activeName = name
        state.isActive = true;
        document.body.style.overflow = 'hidden'
        if (/fail/i.test(name)) {
            state.activeFail = name;
            state.activeName = 'stdFail'
        } else if (/success/i.test(name)) {
            state.activeSuccess = name;
            state.activeName = 'stdSuccess'
        } else {
            state.activeFail = null;
            state.activeSuccess = null;
        }
        state.payload = payload
    },
    close(state, { changeTo = null, payload = null }) {
        if (changeTo && !changeTo.type) {
            if (/fail/i.test(changeTo)) {
                state.activeFail = changeTo;
                state.activeName = 'stdFail'
            } else if (/success/i.test(changeTo)) {
                state.activeSuccess = changeTo;
                state.activeName = 'stdSuccess'
            } else {
                state.activeFail = null;
                state.activeSuccess = null;
                state.activeName = changeTo
            }
            state.payload = payload
            return;
        } else {
            state.activeName = null;
            state.isActive = false;
            state.payload = null
            document.body.style.overflow = 'auto'
        }
    },
}

const getters = {
    currentActive: state => state.activeName,
    getPayload: state => state.payload,
    currentSuccess: state => state.activeSuccess,
    isActive: state => state.isActive
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}
