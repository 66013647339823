import { HTTP } from './default'

export default {
    getDonations() {
        return HTTP({
            url: '/charity/campaign-2021-12/donations/',
            method: 'GET'
        })
    },
    getChildren() {
        return HTTP({
            url: '/charity/campaign-2021-12/childrens/',
            method: 'GET'
        })
    },
    donateToChildren(data) {
        return HTTP({
            url: '/charity/campaign-2021-12/donate/',
            method: 'POST',
            data
        })
    }
}
