import Vue from 'vue';
import VueRouter from 'vue-router';
const Main = () => import('@/Main.vue')

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		component: Main,
		meta: {
			title: 'Новогоднее чудо',
		},
	},
];
const options = {
	mode: 'history',
	routes,
};

const router = new VueRouter(options);

export default router;
