import CH from '@/api/charity';

const state = {
    children: [],
    helped: [],
};

const actions = {
    getDonations({ commit }) {
        return new Promise(resolve => {
            CH.getDonations()
                .then(response => response.data)
                .then(result => {
                    commit('setHelped', result)
                    resolve(result)
                })
        })
    },
    getChildren({ commit }) {
        return new Promise(resolve => {
            CH.getChildren()
                .then(response => response.data)
                .then(result => {
                    commit('setChildren', result)
                    resolve(result)
                })
        })
    },
};

const mutations = {
    setChildren(state, payload) {
        state.children = payload;
    },
    setHelped(state, payload) {
        state.helped = payload;
    },
};

const getters = {
    children: (state) => state.children,
    helped: (state) => state.helped
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
