import Vue from 'vue';
import App from './App.vue';
import VueMask from 'v-mask';
import Vuelidate from 'vuelidate';
import VueScrollTo from 'vue-scrollto';
import './assets/styles/layout.less';
import './assets/styles/styles.less';
import './assets/styles/modal.less';
import './assets/styles/reset.less';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.prototype.$eventBus = new Vue()

Vue.use(VueScrollTo, {
	duration: 900,
	offset: -120,
	easing: 'cubic-bezier(.17,.67,.83,.67)'
});

Vue.mixin({
	filters: {
		toPlural: (value, one, two, five, isShowValue = true) => {
			let n = Math.abs(value);
			n %= 100;
			const quantity = isShowValue ? value : '';
			if (n >= 5 && n <= 20) {
				return `${quantity} ${five}`;
			}
			n %= 10;
			if (n === 1) {
				return `${quantity} ${one}`;
			}
			if (n >= 2 && n <= 4) {
				return `${quantity} ${two}`;
			}
			return `${quantity} ${five}`;
		}
	},
	methods: {
		modalOpen(name, payload = null) {
			this.$store.commit('modal/open', {name, payload})
		},
		modalClose(name = null, payload = null) {
			this.$store.commit('modal/close', {changeTo: name, payload})
			this.$eventBus.$emit('modal-close')
		}
	}
})

new Vue({
	store,
	router,
	render: (h) => h(App),
}).$mount('#app');
